import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/client-endpoint";
import { RouteComponentProps } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";


interface State {
    datasource : {
        Help: ParentStateDatasource<typeof Endpoint.Client.GetHelp>
    }
}

export default class Help extends BaseComponent<RouteComponentProps, State> {
    state: State = {
        datasource: {
            Help: DataSourceStateIdle
        }
    }

    private dsGetHelp = ds(Endpoint.Client.GetHelp, this, "Help", ()=> this.context)

    componentDidMount() {
        this.getHelp();
    }

    render() {
        const dsGetHelpData = this.dsGetHelp.dataSourceStorage;
        if(dsGetHelpData.state === "completed") {
        return (
            
            <div className="page">
                <div className="page__header">Pomoc</div>
                <div className="page-tab-content">
                    <div className="page-details">
                        <div className="page-details__label">pomoc techniczna</div>
                        <div className="page-details__value">
                         {dsGetHelpData.response.Technical.Label}</div>
                        <a className="page-details__value" href={`mailto:${dsGetHelpData.response.Technical.Email}`}>
                            {dsGetHelpData.response.Technical.Email}
                        </a>
                    </div>
                    <div className="page-details">
                        <div className="page-details__label">opiekun klienta</div>
                        <div className="page-details__value">
                         {dsGetHelpData.response.Salesman.Label}</div>
                        <a className="page-details__value" href={`mailto:${dsGetHelpData.response.Salesman.Email}`}>
                            {dsGetHelpData.response.Salesman.Email}
                        </a>
                    </div>
                    <div className="page-details">
                        <div className="page-details__label">opiekun promocji</div>
                        <div className="page-details__value">
                         {dsGetHelpData.response.Coordinator.Label}</div>
                        <a className="page-details__value" href={`mailto:${dsGetHelpData.response.Coordinator.Email}`}>
                            {dsGetHelpData.response.Coordinator.Email}
                        </a>
                    </div>
                </div>
            </div>
        );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        )
    }

    get overlayProps(): OverlayProps {
        if (this.dsGetHelp.state === "pending" || this.dsGetHelp.state === "idle"){
            return {
                show: true,
                title:"Ładowanie"
            }
        }
        if(this.dsGetHelp.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsGetHelp.error === "string" ? this.dsGetHelp.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={()=> this.getHelp()} >spróbuj ponownie</button>
                    </div>
                )
            }
        }
        return {
            show: false
        }
    }

    private getHelp() {
        this.dsGetHelp.request({});
        
    }

}
