import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, FormValidationFunction, ValidateEmail, Form, FormTextField } from "@schneiderpp/utils-forms";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTER_AUTH } from "./Router";

interface ResetPasswordSendEmailFormFields {
    email: string;
}

interface ResetPasswordSendEmailState {
    fields: ResetPasswordSendEmailFormFields;
    fieldErrors: FormValidationError<ResetPasswordSendEmailFormFields>[]
}

const resetPasswordSendEmailFormValidate: FormValidationFunction<ResetPasswordSendEmailFormFields> = async fields => {
    const errors: Array<FormValidationError<ResetPasswordSendEmailFormFields>> = [];
    if(!ValidateEmail(fields.email)) {
        errors.push({fieldName: "email", code: "Invalid Email"})
    }
    return errors;
}

class ResetPasswordSendEmail extends BaseComponent<RouteComponentProps, ResetPasswordSendEmailState> {
    state: ResetPasswordSendEmailState = {
        fields: {
            email: "",
        },
        fieldErrors: [],
    }

    private form = new Form<ResetPasswordSendEmailFormFields>(this, resetPasswordSendEmailFormValidate)

    render() {
        return (
            <div className="auth__center">
                <div className="auth__container">
                    <div className="auth__title">Zresetuj hasło: Podaj Email</div>
                    <FormTextField config={this.form.getFieldConfig("email")} label="email" />
                    <button onClick={()=> this.resetPasswordSendEmail()} className="button">wyślij maila z kodem</button>
                </div>
            </div>
        )
    }

    private async resetPasswordSendEmail() {
        try {
            const isValid = await this.form.validate();
            if(isValid) {
                const searchParams = new URLSearchParams();
                searchParams.set("email", this.form.fields.email);

                this.props.history.push({pathname: ROUTER_AUTH.ResetPasswordSetNew, search: searchParams.toString()})
            }
        } catch (e) {}
    }
}

export default withRouter(ResetPasswordSendEmail)