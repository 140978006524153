import React from "react";
import ReactMarkdown from "react-markdown";

import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";

import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { Endpoint } from "@schneiderpp/client-endpoint";

import { ds } from "../../DataSource";

import "./Agreement.scss";

interface Agreement {
    AgreementId: number;
    Name: string;
    Description?: string;
    IsRequired: boolean;
    LinkToDocument?: string;
}

interface AgreementsProps {
    Agreements: Agreement[];
    RefreshAgreements: () => void;
}

interface AgreementsState {
    CheckedAgreements: number[];
    datasource: {
        SaveAgreements: ParentStateDatasource<typeof Endpoint.Client.PostSaveAgreements>;
    };
}

export default class Agreements extends BaseComponent<AgreementsProps, AgreementsState> {
    state: AgreementsState = {
        CheckedAgreements: [],
        datasource: {
            SaveAgreements: DataSourceStateIdle
        }
    };

    private dsSaveAgreements = ds(Endpoint.Client.PostSaveAgreements, this, "SaveAgreements", () => this.context);

    render() {
        return (
            <div className="page">
                <div className="page__header">Zgody wymagane do korzystania z systemu</div>
                {this.props.Agreements.map(a => {
                    const isChecked = this.state.CheckedAgreements.includes(a.AgreementId);
                    return (
                        <div className="agreement" key={a.AgreementId}>
                            <div className="agreement__title">{a.Name}</div>
                            {!!a.Description ? (
                                <div className="agreement__description">
                                    <ReactMarkdown source={a.Description} />
                                </div>
                            ) : null}
                            <div className="agreement__check">
                                <label className="agreement__check-wrapper">
                                    <input
                                        className="agreement__check-box"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={e => this.onAgreementChange(a.AgreementId, e.target.checked)}
                                        id={`agreement-${a.AgreementId}`}
                                    />
                                    <div className={`agreement__check-square ${isChecked ? "checked" : ""}`}>check</div>
                                    <div className="agreement__check-label">Wyrażam zgodę</div>
                                </label>
                            </div>
                        </div>
                    );
                })}
                <div className="page-buttons">
                    <button
                        className={`button align-self-end margin-top-10 ${!this.isValid ? "disabled" : ""}`}
                        onClick={() => this.submit()}
                    >
                        zapisz
                    </button>
                </div>
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsSaveAgreements.state === "pending") {
            return {
                show: true,
                title: "Zapisuje..."
            };
        }

        if (this.dsSaveAgreements.state === "completed") {
            return {
                show: true,
                title: "Zapisano"
            };
        }

        if (this.dsSaveAgreements.state === "error") {
            return {
                show: true,
                title: "Błąd zapisywania",
                description: typeof this.dsSaveAgreements.error === "string" ? this.dsSaveAgreements.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsSaveAgreements.resetState()}>Ok</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    get isValid(): boolean {
        for (let i = 0; i < this.props.Agreements.length; i++) {
            if (this.state.CheckedAgreements.findIndex(a => a === this.props.Agreements[i].AgreementId) === -1) {
                return false;
            }
        }
        return true;
    }

    private onAgreementChange(agreementId: number, checked: boolean) {
        this.setState(p => {
            const t = p.CheckedAgreements;
            const aI = t.findIndex(a => a === agreementId);

            if (aI === -1 && !!checked) {
                t.push(agreementId);
            } else if (aI !== -1 && !checked) {
                t.splice(aI, 1);
            }
            return {
                CheckedAgreements: t
            };
        });
    }

    private async submit() {
        if (!this.isValid) {
            return;
        }

        await this.dsSaveAgreements.request({ data: { Agreements: this.state.CheckedAgreements } });

        if (this.dsSaveAgreements.state === "completed") {
            this.props.RefreshAgreements();
        }
    }
}
