import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { ROUTER_HOME } from "./Router";
import logo from "../../assets/LIO-Logos-SE.png";
import { BaseComponent } from "../../utils/BaseComponent";

interface State {
    isOpen: boolean;
}

class HomeNav extends BaseComponent<{}, State> {
    state: State = {
        isOpen: false
    };

    private getToken = async () => {
        const token = await this.context.Authorization.getToken();
        console.log(token);
        if (!!navigator && !!navigator.clipboard) {
            navigator.clipboard.writeText(token);
        }
    };

    render() {
        return (
            <div className={`nav ${this.state.isOpen ? "is-open" : ""}`}>
                <div className="nav__center">
                    <div className="nav__logo">
                        <img src={logo} className="nav__logo-image"></img>
                    </div>
                    <div className="nav__right">
                        <div className="nav__right-button" onClick={() => this.changeState()}>
                            {this.state.isOpen ? "close" : "notes"}
                        </div>
                    </div>
                    <div className="nav__links">
                        <div className="nav__links-left">
                            <NavLink to={ROUTER_HOME.Client.Base} className="nav__link" onClick={() => this.close()}>
                                Moje Konto
                            </NavLink>
                            <NavLink to={ROUTER_HOME.Orders.Base} className="nav__link" onClick={() => this.close()}>
                                Nagrody
                            </NavLink>
                            <NavLink to={ROUTER_HOME.Supplies.Base} className="nav__link" onClick={() => this.close()}>
                                Zatowarowania
                            </NavLink>
                        </div>
                        <div className={`nav__logout ${this.state.isOpen ? "border-top" : ""} `}>
                            {APP_CONFIG.ENV === "dev" ? (
                                <button onClick={() => this.getToken()} className="button">
                                    Token
                                </button>
                            ) : null}
                            <div className="nav__logout-help">
                                <NavLink to={ROUTER_HOME.Client.Help} className="nav__logout-help-button" onClick={() => this.close()}>
                                    help
                                </NavLink>
                            </div>
                            <button
                                onClick={() => this.context.Authorization.logout()}
                                className={`button ${this.state.isOpen ? "border-none" : ""}`}
                            >
                                Wyloguj
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private changeState() {
        this.setState((p) => ({ isOpen: !p.isOpen }));
    }

    private close() {
        this.setState((p) => ({ isOpen: false }));
    }
}

export default HomeNav;
