import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";

import Nav from "./Nav";
import ClientRouter, { ROUTER_CLIENT } from "../client/Router";
import OrderRouter, { ROUTER_ORDERS } from "../orders/Router";
import SuppliesRouter, { ROUTER_SUPPLIES } from "../supplies/Router";
import { Route, Switch } from "react-router-dom";
import Agreements from "./Agreements";

import { Endpoint } from "@schneiderpp/client-endpoint";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";

import { ds } from "../../DataSource";
import { Overlay, OverlayProps } from "@schneiderpp/utils-components";

export const ROUTER_HOME = {
    Client: ROUTER_CLIENT,
    Orders: ROUTER_ORDERS,
    Supplies: ROUTER_SUPPLIES
};

interface HomeRouterState {
    datasource: {
        MissingAgreements: ParentStateDatasource<typeof Endpoint.Client.GetMissingAgreements>;
    };
}

export default class HomeRouter extends BaseComponent<{}, HomeRouterState> {
    state: HomeRouterState = {
        datasource: {
            MissingAgreements: DataSourceStateIdle
        }
    };

    private dsMissingAgreements = ds(Endpoint.Client.GetMissingAgreements, this, "MissingAgreements", () => this.context);

    componentDidMount() {
        this.getMissingAgreements();
    }

    render() {
        if (
            this.dsMissingAgreements.state === "pending" ||
            this.dsMissingAgreements.state === "idle" ||
            this.dsMissingAgreements.state === "error"
        ) {
            return (
                <div className="router-home">
                    <div className="router-home__content">
                        <div className="page">
                            <Overlay {...this.overlayProps} />
                        </div>
                    </div>
                </div>
            );
        }
        const dsMissingAgreements = this.dsMissingAgreements.dataSourceStorage;
        if (dsMissingAgreements.state === "completed" && dsMissingAgreements.response.Agreements.length > 0) {
            return (
                <div className="router-home">
                    <div className="router-home__content">
                        <Agreements
                            Agreements={dsMissingAgreements.response.Agreements}
                            RefreshAgreements={() => this.getMissingAgreements()}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className="router-home">
                <Nav />
                <div className="router-home__content">
                    <Switch>
                        <Route path={ROUTER_HOME.Client.Base} component={ClientRouter} />
                        <Route path={ROUTER_HOME.Orders.Base} component={OrderRouter} />
                        <Route path={ROUTER_HOME.Supplies.Base} component={SuppliesRouter} />
                    </Switch>
                </div>
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsMissingAgreements.state === "idle" || this.dsMissingAgreements.state === "pending") {
            return {
                show: true,
                title: "Ładowanie aplikacji..."
            };
        }
        if (this.dsMissingAgreements.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsMissingAgreements.error === "string" ? this.dsMissingAgreements.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getMissingAgreements()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getMissingAgreements() {
        this.dsMissingAgreements.request({});
    }
}
