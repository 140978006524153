import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, FormValidationFunction, ValidateEmail, Form, FormTextField } from "@schneiderpp/utils-forms";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTER_AUTH } from "./Router";
interface ResetPasswordSetNewFormFields {
    email: string;
    authenticationCode: string;
    newpassword: string;
}

interface ResetPasswordSetNewState {
    fields: ResetPasswordSetNewFormFields;
    fieldErrors: FormValidationError<ResetPasswordSetNewFormFields>[];
    urlProvided: {
        email: boolean;
        authenticationCode: boolean;
    };
}

const resetPasswordSetNewFormValidate: FormValidationFunction<ResetPasswordSetNewFormFields> = async fields => {
    const errors: Array<FormValidationError<ResetPasswordSetNewFormFields>> = [];
    if (!ValidateEmail(fields.email)) {
        errors.push({ fieldName: "email", code: "Invalid Email" });
    }
    if (fields.authenticationCode.length < 3) {
        errors.push({
            fieldName: "authenticationCode",
            code: "Authentication code is too short"
        });
    }
    if (fields.newpassword.length < 6) {
        errors.push({
            fieldName: "newpassword",
            code: "New password is too short"
        });
    }
    return errors;
};

class ResetPasswordSetNew extends BaseComponent<RouteComponentProps, ResetPasswordSetNewState> {
    state: ResetPasswordSetNewState = {
        fields: {
            email: "",
            authenticationCode: "",
            newpassword: ""
        },
        fieldErrors: [],
        urlProvided: {
            email: false,
            authenticationCode: false
        }
    };

    private form = new Form<ResetPasswordSetNewFormFields>(this, resetPasswordSetNewFormValidate);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.get("email"));

        const email = urlParams.get("email");
        const authenticationCode = urlParams.get("code");

        if (!!email && ValidateEmail(email)) {
            this.setState(p => ({
                fields: { ...p.fields, email: email },
                urlProvided: { ...p.urlProvided, email: true }
            }));
        }

        if (!!authenticationCode && authenticationCode.length >= 6) {
            this.setState(p => ({
                fields: { ...p.fields, authenticationCode: authenticationCode },
                urlProvided: { ...p.urlProvided, authenticationCode: true }
            }));
        }
    }
    render() {
        return (
            <div className="auth__center">
                <div className="auth__container">
                    <div className="auth__title">Resetuj Hasło</div>
                    {!this.state.urlProvided.email ? (
                        <FormTextField config={this.form.getFieldConfig("email")} label="email" />
                    ) : (
                        <div className="form__label">{this.state.fields.email}</div>
                    )}
                    {!this.state.urlProvided.authenticationCode ? (
                        <FormTextField config={this.form.getFieldConfig("authenticationCode")} label="authentication code" />
                    ) : (
                        <div className="form__label">{this.state.fields.authenticationCode}</div>
                    )}
                    <FormTextField config={this.form.getFieldConfig("newpassword")} label="new password" type="password" />
                    <button className="button" onClick={()=> this.resetPasswordSetNew()}>Zatwierdź</button>
                </div>
            </div>
        );
    }

    private async resetPasswordSetNew() {
        try {
            const isValid = await this.form.validate();
            if(isValid) {
                this.props.history.push({pathname: ROUTER_AUTH.Login})
            }
        } catch (e) {}
    }
}

export default withRouter(ResetPasswordSetNew);
