import React from "react";

import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";

import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { Endpoint } from "@schneiderpp/client-endpoint";
import { FormatFloat } from "@schneiderpp/utils-generic";
import { ds } from "../../DataSource";
import { ROUTER_HOME } from "../home/Router";
import baner from "../../assets/Baner.png";

interface DetailsState {
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Client.GetClientDetails>;
    };
}

export default class Details extends BaseComponent<{}, DetailsState> {
    state: DetailsState = {
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Client.GetClientDetails, this, "Details", () => this.context);

    componentDidMount() {
        this.getDetails();
    }

    render() {
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <div className="page no-padding-top">
                    <div className="page-banner">
                        <img src={baner} className="page-banner__img" />
                    </div>
                    <div className="page__column-view">
                        <div className="page__column">
                            <div className="page-tab-content">
                                <div className="page-details">
                                    <div className="page-details__label">Imię i nazwisko</div>
                                    <div className="page-details__value">{dsDetailsData.response.Name}</div>
                                </div>
                                <div className="page-details">
                                    <div className="page-details__label">Email </div>
                                    <div className="page-details__value">{dsDetailsData.response.Email}</div>
                                </div>
                                <div className="page-details">
                                    <div className="page-details__label">Nazwa Firmy</div>
                                    <div className="page-details__value">{dsDetailsData.response.Company}</div>
                                </div>
                                <div className="page-details">
                                    <div className="page-details__label">Opiekun Klienta</div>
                                    <div className="page-details__value">{dsDetailsData.response.Salesman.Name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="page__column">
                            <div className="page-tab-content">
                                <div className="page-details">
                                    <div className="page-details__label">Stan Konta</div>
                                    <div className="page-details__value">{FormatFloat(dsDetailsData.response.Balance)} ZŁ</div>
                                </div>

                                <div className="page-details">
                                    <div className="page-details__label">Adres dostawy</div>
                                    <div className="page-details__value">{dsDetailsData.response.Street}</div>
                                    <div className="page-details__value">
                                        {dsDetailsData.response.City}, {dsDetailsData.response.PostalCode}
                                    </div>
                                    <div className="page-details__value">{dsDetailsData.response.AddressComment}</div>
                                </div>
                                <div className="page-details">
                                    <div className="page-details__label">Numer telefonu</div>
                                    <div className="page-details__value">{dsDetailsData.response.Telephone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-buttons">
                        <Link to={ROUTER_HOME.Client.EditAdress} className="button small">
                            Edytuj Adres
                        </Link>
                    </div>
                </div>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie"
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getDetails()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        this.dsDetails.request({});
    }
}
