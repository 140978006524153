import React from "react";
import List from "./List";
import Details from "./Details";
import { Route, Switch, Redirect } from "react-router-dom";
import Add from "./Add";

const ROUTER_ORDERS_BASE = "/Orders";

export const ROUTER_ORDERS = {
    Base: ROUTER_ORDERS_BASE,
    List: ROUTER_ORDERS_BASE + "/List",
    Details: ROUTER_ORDERS_BASE + "/Details",
    Add: ROUTER_ORDERS_BASE + "/ChangeOrderStatus"
};

const OrderRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_ORDERS.List} component={List} />
            <Route path={ROUTER_ORDERS.Details} component={Details} />
            <Route path={ROUTER_ORDERS.Add} component={Add} />
            <Redirect path="*" to={ROUTER_ORDERS.List} />
        </Switch>
    );
};

export default OrderRouter;
