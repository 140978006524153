import React from "react";
import List from "./List";
import Details from "./Details";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_SUPPLIES_BASE = "/Supplies";

export const ROUTER_SUPPLIES = {
    Base: ROUTER_SUPPLIES_BASE,
    List: ROUTER_SUPPLIES_BASE + "/List",
    Details: ROUTER_SUPPLIES_BASE + "/Details",
};

const SuppliesRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_SUPPLIES.List} component={List} />
            <Route path={ROUTER_SUPPLIES.Details} component={Details} />
            <Redirect path="*" to={ROUTER_SUPPLIES.List} />
        </Switch>
    );
};

export default SuppliesRouter;
