import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ContextApp, ContextAppValues, ContextAppState, ContextAppStateDefault } from "./context";

import Router from "./Router";

interface AppState {
    context: ContextAppState;
}

export default class App extends React.Component<{}, AppState> {
    state: AppState = {
        context: ContextAppStateDefault
    };

    private contextValues = ContextAppValues(this);

    render() {
        return (
            <ContextApp.Provider value={this.contextValues}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </ContextApp.Provider>
        );
    }
}
