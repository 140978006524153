import React from "react";

import RouterAuth from "./areas/auth/Router";
import Router from "./areas/home/Router";

import { BaseComponent } from "./utils/BaseComponent";

export const ROUTES = {};

export default class RouterApp extends BaseComponent {
    componentDidMount() {
        this.context.Authorization.checkAuth();
    }

    render() {
        if (this.context.Authorization.isAuthCheckState === "pending" || this.context.Authorization.isAuthCheckState === "idle") {
            return <div className="router__state">Logowanie...</div>;
        }

        if (this.context.Authorization.isAuthCheckState === "error") {
            return <div className="router__state">Błąd podczas logowania...</div>;
        }

        if (this.context.Authorization.isAuthCheckState === "completed" && this.context.Authorization.isAuth) {
            return <Router />;
        }

        return <RouterAuth />;
    }
}
