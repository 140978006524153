import React, { Children } from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import { Overlay, OverlayProps } from "@schneiderpp/utils-components";

import { Form, FormTextField, FormValidationError, FormValidationFunction } from "@schneiderpp/utils-forms";

import "./Auth.scss";

import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";
import { CognitoLoginError } from "@schneiderpp/utils-plugins";
import { Link } from "react-router-dom";
import { ROUTER_AUTH } from "./Router";

interface LoginFormFields {
    username: string;
    password: string;
}

interface LoginState {
    fields: LoginFormFields;
    fieldErrors: FormValidationError<LoginFormFields>[];
    changePasswordState: boolean;
    loginState: "idle" | "pending" | "completed" | "error";
    loginError: string | undefined;
    setNewPasswordState: "idle" | "pending" | "completed" | "error";
    setNewPasswordError: string | undefined;
}

const loginFormValidate: FormValidationFunction<LoginFormFields> = async fields => {
    const errors: Array<FormValidationError<LoginFormFields>> = [];

    if (fields.username.length < 4) {
        errors.push({ fieldName: "username", code: "UsernameTooShort" });
    }
    if (fields.password.length < 1) {
        errors.push({ fieldName: "password", code: "PasswordTooShort" });
    }
    return errors;
};
export default class Login extends BaseComponent<{}, LoginState> {
    state: LoginState = {
        fields: {
            username: "",
            password: ""
        },
        fieldErrors: [],
        changePasswordState: false,
        loginState: "idle",
        loginError: undefined,
        setNewPasswordState: "idle",
        setNewPasswordError: undefined
    };

    private form = new Form<LoginFormFields>(this, loginFormValidate, code => code);
    private userAttributes: CognitoUserAttribute[] = [];
    private cognitoUser: CognitoUser | undefined;

    render() {
        if (this.state.changePasswordState) {
            return (
                <div className="auth__center">
                    <div className="auth__container">
                        <div className="auth__title">Ustaw nowe hasło</div>
                        <FormTextField config={this.form.getFieldConfig("password")} label="Hasło" />
                        <button onClick={() => this.setNewPassword()} className="button">
                            Ustaw hasło
                        </button>
                        <Overlay {...this.overlayProps} />
                    </div>
                </div>
            );
        }

        return (
            <div className="auth__center">
                <div className="auth__container">
                    <div className="auth__title">Logowanie</div>
                    <FormTextField config={this.form.getFieldConfig("username")} label="nazwa użytkownika"></FormTextField>
                    <FormTextField
                        config={this.form.getFieldConfig("password")}
                        label="hasło"
                        type="password"
                        onEnter={() => this.login()}
                    ></FormTextField>
                    <button onClick={() => this.login()} className="button">
                        Zaloguj
                    </button>
                    <Link to={ROUTER_AUTH.ResetPasswordSendEmail} className="auth__bottom-link">
                        Zresetuj hasło
                    </Link>
                    <Overlay {...this.overlayProps} />
                </div>
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.state.loginState === "pending") {
            return {
                show: true,
                title: "Logowanie..."
            };
        }
        if (this.state.loginState === "error") {
            return {
                show: true,
                title: "Błąd logowania :(",
                description: this.state.loginError || "Wystąpił błąd podczas logowania, spróbuj ponowie.",
                children: (
                    <div className="overlay__children">
                        <button
                            onClick={() =>
                                this.setState({
                                    loginState: "idle",
                                    loginError: undefined
                                })
                            }
                            className="button light"
                        >
                            Ok
                        </button>
                    </div>
                )
            };
        }
        if (this.state.setNewPasswordState === "pending") {
            return {
                show: true,
                title: "Zapisuje hasło..."
            };
        }
        if (this.state.setNewPasswordState === "error") {
            return {
                show: true,
                title: "Błąd :(",
                description: this.state.setNewPasswordError || "Wystąpił błąd podczas zapisywania hasła, spróbuj ponownie.",
                children: (
                    <div>
                        <button
                            onClick={() =>
                                this.setState({
                                    setNewPasswordState: "idle",
                                    setNewPasswordError: undefined
                                })
                            }
                            className="button"
                        >
                            Ok
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async login() {
        try {
            const isValid = await this.form.validate();
            if (isValid) {
                this.setState({ loginState: "pending" });
                const response = await this.context.Authorization.login(this.form.fields.username, this.form.fields.password);
                // this.setState({ loginState: "completed" });

                if (response.RequirePasswordChange) {
                    this.userAttributes = response.SessionUserAttributes;
                    this.cognitoUser = response.CognitoUser;
                    this.setState(p => ({
                        loginState: "completed",
                        changePasswordState: true,
                        fields: { ...p.fields, password: "" }
                    }));
                }
            }
        } catch (e) {
            if (!!e.code) {
                this.setState({
                    loginState: "error",
                    loginError: this.CognitoLoginErrorToText(e.code)
                });
            } else {
                this.setState({ loginState: "error" });
            }
        }
    }

    private async setNewPassword() {
        if (!this.cognitoUser) {
            return;
        }
        try {
            const isValid = await this.form.validate();
            if (isValid) {
                this.setState({ setNewPasswordState: "pending" });
                await this.context.Authorization.setNewPassword(this.cognitoUser, this.form.fields.password, this.userAttributes);
                // this.setState({ setNewPasswordState: "completed" });
            }
        } catch (e) {
            if (!!e.code) {
                this.setState({
                    setNewPasswordState: "error",
                    setNewPasswordError: e.code
                });

                console.log(e.code);
                return;
            }
            this.setState({ setNewPasswordState: "error" });
        }
    }

    private CognitoLoginErrorToText(e: CognitoLoginError) {
        switch (e) {
            case "NotAuthorizedException":
                return "Użytkownik lub hasło niepoprawne";
        }
    }
}
