import { createContext } from "react";

import {
    AuthorizationCognito,
    ICognitoStorage,
    AuthorizationCognitoStorage,
    AuthorizationCognitoStorageDefault
} from "@schneiderpp/utils-plugins";

import App from "../App";

export interface ContextApp {
    Authorization: AuthorizationCognito;
}

export interface ContextAppState {
    authorization: AuthorizationCognitoStorage;
}

export const ContextAppStateDefault = {
    ...AuthorizationCognitoStorageDefault
};

export function ContextAppValues(parent: App): ContextApp {
    return {
        Authorization: new AuthorizationCognito(
            parent,
            APP_CONFIG.COGNITO.POOL_ID,
            APP_CONFIG.COGNITO.CLIENT_ID,
            () => localStorage as ICognitoStorage
        )
    };
}
export const ContextApp = createContext<ContextApp>({} as ContextApp);
