import React from "react";
import Login from "./Login";
import ResetPasswordSetNew from "./ResetPasswordSetNew";
import ResetPasswordSendEmail from "./ResetPasswordSendEmail";
import { Switch, Route, Redirect } from "react-router-dom";

const ROUTER_AUTH_BASE = "/Auth";
export const ROUTER_AUTH = {
    Base: ROUTER_AUTH_BASE,
    Login: ROUTER_AUTH_BASE + "/Login",
    ResetPasswordSendEmail: ROUTER_AUTH_BASE + "/ResetPasswordSendEmail",
    ResetPasswordSetNew: ROUTER_AUTH_BASE + "/ResetPasswordSetNew"
}

const RouterAuth: React.FC = () => {
    return (
        <div className="auth__center">
            <Switch>
                <Route exact path={ROUTER_AUTH.Login} component={Login} />
                <Route exact path={ROUTER_AUTH.ResetPasswordSendEmail} component={ResetPasswordSendEmail} />
                <Route exact path={ROUTER_AUTH.ResetPasswordSetNew} component={ResetPasswordSetNew} />
                <Redirect path="*" to={ROUTER_AUTH.Login} />
            </Switch>
        </div>
    )
}

export default RouterAuth