import React from "react";
import Details from "./Details";
import EditAdress from "./NewAddress";
import Help from "./Help";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_CLIENT_BASE = "/Client";

export const ROUTER_CLIENT = {
    Base: ROUTER_CLIENT_BASE,
    Details: ROUTER_CLIENT_BASE + "/Details",
    SendInvitation: ROUTER_CLIENT_BASE + "/SendInvitation",
    EditAdress: ROUTER_CLIENT_BASE + "/EditAdress",
    Help: ROUTER_CLIENT_BASE + "/Help"
};

const ClientRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_CLIENT.Details} component={Details} />
            <Route path={ROUTER_CLIENT.EditAdress} component={EditAdress} />
            <Route path={ROUTER_CLIENT.Help} component={Help} />
            <Redirect path="*" to={ROUTER_CLIENT.Details} />
        </Switch>
    );
};

export default ClientRouter;
