import { EndpointWeb, EndpointDefinition, Parent, DataSource } from "@schneiderpp/utils-endpoint";

import { ContextApp } from "./context";

export function ep<TEndpointDefinition extends EndpointDefinition<any, any, any, boolean>>(
    endpointDefinition: TEndpointDefinition,
    contextApp: ContextApp
) {
    return new EndpointWeb<TEndpointDefinition>({ ...endpointDefinition, url: APP_CONFIG.API_URL + endpointDefinition.url }, () =>
        contextApp.Authorization.getToken()
    );
}

export function ds<TEndpointDefinition extends EndpointDefinition<any, any, any, boolean>, TStorageKey extends string>(
    endpointDefinition: TEndpointDefinition,
    parent: Parent<TEndpointDefinition, TStorageKey>,
    storageKey: TStorageKey,
    contextApp: () => ContextApp
) {
    return new DataSource<TEndpointDefinition, TStorageKey>(
        { ...endpointDefinition, url: APP_CONFIG.API_URL + endpointDefinition.url },
        parent,
        storageKey,
        (endpointDefinition, tokenFunction) =>
            !!tokenFunction
                ? new EndpointWeb<TEndpointDefinition>(endpointDefinition, tokenFunction)
                : new EndpointWeb<TEndpointDefinition>(endpointDefinition),
        () => contextApp().Authorization.getToken()
    );
}
